<template>
  <portal to="debug" :disabled="!portal">
    <vue-draggable-resizable
        ref="element_layout"
        :y="y"
        :x="x"
        :draggable="true"
        :z="99999999999999"
        :is="!portal ? 'span' : 'vue-draggable-resizable'"
        :style="portal ? `position: ${position}` : ''"

    >

        <v-sheet min-width="500" dark max-height="100vh" class="overflow-auto align-center" :class="[{'d-flex' : typeof d !== 'object' }]">
          <v-sheet dark class="d-flex mr-10" width="100%">
            <ws-text-field v-model="search" append-icon="mdi-magnify" avalon-style ></ws-text-field>
            <v-btn icon @click="portal = !portal"><v-icon>mdi-{{ !portal ? 'resize' : 'move-resize-variant' }}</v-icon></v-btn>
          </v-sheet>

          <v-divider />

          <slot>
            <div ref="debug_object">
              <v-simple-table   v-if="typeof d === 'object'" style="width: 100%"  class="pointer pb-4 " dark dense>
                <tbody>
                <template v-for="(value,key, index) in d" >
                  <template v-if="!first || (first && index === 0)">
                    <tr v-if="!exclude.includes(key) && (!search || key.includes(search))"
                        :key="key">
                      <td width="10px" @click="copy(key)">
                        <h4 class="text-no-wrap"> <strong> {{ key }} </strong>  </h4>
                      </td>
                      <td v-if="!keys" @click.stop="copy(key)" >
                        <debug-object
                            v-if="typeof value === 'object'"
                            :d="value"
                            child
                        />
                        <h4 v-else class="font-weight-regular">{{ value }}</h4>
                      </td>
                    </tr>
                  </template>
                </template>

                </tbody>

              </v-simple-table>
              <div v-else>
                {{ n }} {{ d }}
              </div>
            </div>
          </slot>



        </v-sheet>
    </vue-draggable-resizable>

  </portal>

</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
export default {
  name: "debugObject",
  components : {
    VueDraggableResizable
  },
  props : {
    d : {
    },
    n : {
      type : String
    },
    portal : {
      type : Boolean,
      default : false
    },
    child : {
      type : Boolean,
      default : false
    },
    first : {
      type : Boolean,
      default : false
    },
    keys : {
      type : Boolean,
      default : false
    },
    x : {
      type : Number,
      default : 0
    },
    y : {
      type : Number,
      default : 0
    },
    exclude : {
      type : Array,
      default() { return [] }
    },
    search : {
      type : String
    }
  },
  data() {
    return {
      position : 'absolute'
    }
  },
  methods : {
    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notify(this.$t('CopiedToClipboard') ,null, 'mdi-clipboard-edit-outline' )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },
  }
}
</script>

<style scoped>

</style>